import Turbolinks from 'turbolinks'
import Swiper from 'swiper/bundle'
import 'alpinejs'
import 'swiper/swiper-bundle.css'
// Tell Webpack to pull in CSS into the JS
import '../css/application.scss'
// Tell webpack to pull in images as well
require.context('../images', true)
Turbolinks.start()

document.addEventListener('turbolinks:load', function () {
  const testimonialSlider = new Swiper('#testimonialSlider', {
    slidesPerView: 1,
    spaceBetween: 12,
    loop: true,
    autoplay: {
      delay: 3000,
    },

    navigation: {
      nextEl: '.testimonial-slider-next',
      prevEl: '.testimonial-slider-prev',
    },
  })

  const businessSlider = new Swiper('#businessSlider', {
    slidesPerView: 1.15,
    spaceBetween: 12,
    loop: true,
    autoplay: {
      delay: 3000,
    },
    speed: 550,

    navigation: {
      nextEl: '.business-slider-next',
      prevEl: '.business-slider-prev',
    },
  })

  const charitySlider = new Swiper('#charitySlider', {
    slidesPerView: 1,
    loop: true,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    autoplay: {
      delay: 5000,
    },
    speed: 700,
  })

  const beautySlider = new Swiper('.beautySlider', {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    autoplay: {
      delay: 3000,
    },

    navigation: {
      nextEl: '.beauty-slider-next',
      prevEl: '.beauty-slider-prev',
    },
  })

  const developerSlider = new Swiper('#developerSlider', {
    slidesPerView: 1,
    spaceBetween: 32,
    loop: true,
    autoplay: {
      delay: 3000,
    },

    navigation: {
      nextEl: '.developer-slider-next',
      prevEl: '.developer-slider-prev',
    },
  })
})
